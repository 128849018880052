import * as DataEnums from '@models/data.enums';

export enum DashboardGridUrls {
  SUPER_ADMIN = 'tickets/grid',
  SUPERVISOR = 'tickets/grid',
  TEAM_ADMIN = 'tickets/grid',
  USER = 'tickets/grid',
  GROUP_MANAGEMENT = 'responsibleTeam/grid',
  ADMIN_USERS = 'user/grid',
  MY_REQUEST = 'tickets/my-requests/grid',
}

export enum DashboardHeaders {
  SUPER_ADMIN = 'Admin dashboard',
  SUPERVISOR = 'Supervisor dashboard',
  TEAM_ADMIN = 'team dashboard',
  USER = 'Issues',
  GROUP_MANAGEMENT = 'Group management',
  ADMIN_USERS = 'Admins',
}


export const DashboardHeader = {
  [DataEnums.UserRole.SuperAdmin]: DashboardHeaders.SUPER_ADMIN,
  [DataEnums.UserRole.SuperVisor]: DashboardHeaders.SUPERVISOR,
  [DataEnums.UserRole.TeamAdmin]: DashboardHeaders.TEAM_ADMIN,
  [DataEnums.UserRole.GeneralPublic]: DashboardHeaders.USER,
};

export const DashboardUrl = {
  [DataEnums.UserRole.SuperAdmin]: DashboardGridUrls.SUPER_ADMIN,
  [DataEnums.UserRole.SuperVisor]: DashboardGridUrls.SUPERVISOR,
  [DataEnums.UserRole.TeamAdmin]: DashboardGridUrls.TEAM_ADMIN,
  [DataEnums.UserRole.GeneralPublic]: DashboardGridUrls.USER,
};
