import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogisticAreaSelectComponent } from './logistic-area-select.component';
import { SelectModule } from '@usitsdasdesign/dds-ng/select';
import { MultiSelectModule } from '@usitsdasdesign/dds-ng/multi-select';


@NgModule({
  declarations: [
    LogisticAreaSelectComponent
  ],
  imports: [
    CommonModule,
    SelectModule
  ],
  exports: [
    LogisticAreaSelectComponent
  ]
})
export class LogisticAreaSelectModule { }
