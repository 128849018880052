import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILogisticArea } from '@models/data.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BuildingsApiService } from '@api/buildings.api.service';
import { tap } from 'rxjs/operators';
import { noop } from 'rxjs';

@Component({
  selector: 'app-logistic-area-select',
  templateUrl: './logistic-area-select.component.html',
  styleUrls: ['./logistic-area-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LogisticAreaSelectComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogisticAreaSelectComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() placeholder = 'Search';
  @Input() size = Size.lg;
  @Input() Id: number;
  @Input() selectedTypeValue:number|null;
  @Input() selectedAreaValue:number|null;
  @ViewChild("logisticAreaDropdown") logisticAreaDropdown: ElementRef;

  public logisticarea: ILogisticArea[] = [];
  public onChange: (value: number | null) => void = noop;
  public onTouched: () => void = noop;
  public control = new FormControl(null);
  public disabled = false;
  public selectedlogisticarea: string;
  constructor(private buildingsApiService: BuildingsApiService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.Id?.firstChange) {
      if(this.selectedAreaValue ==null)
      {
      this.selectedlogisticarea = '';
      }
      else{
        this.onChange(+this.selectedAreaValue);
        this.onTouched();
        this.cd.detectChanges();
       this.selectedlogisticarea=String(this.selectedAreaValue);
      }
      this.fetchLogisticArea();
    }
  }

  public writeValue(value: number): void {
    this.selectedlogisticarea = String(value);
    this.cd.markForCheck();
  }

  public registerOnChange(fn: (value: number | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(state: boolean): void {
    if (state) {
      this.selectedlogisticarea = '';
    }
    this.disabled = state;
    this.cd.detectChanges();
  }

  public onValueChange(val: string): void {
    this.onChange(+val);
    this.onTouched();
    this.selectedlogisticarea = val;
  }

  private async fetchLogisticArea() {
    if (!this.Id && !this.selectedTypeValue) {
      return;
    }
    if(this.Id) {
     await this.buildingsApiService.getLogisticArea(this.Id, '').pipe(
        untilDestroyed(this),
        tap(res => {
          this.logisticarea = res;
          this.cd.detectChanges();
          this.selectedAreaValue=null; 
        })
      ).subscribe();
    }
    else if(this.selectedTypeValue) {
      this.disabled = false;
     await this.buildingsApiService.getLogisticArea(this.selectedTypeValue, '').pipe(
        untilDestroyed(this),
        tap(res => {
          this.logisticarea = res;
          console.log(res);
          this.cd.detectChanges();
        })
      ).subscribe(
        ()=> {
        }, ()=> {}, ()=> {
          if(this.selectedAreaValue != null && this.selectedAreaValue != 0 && this.selectedAreaValue != undefined) {
            this.writeValue(this.selectedAreaValue);
          }
        }
      );
    }
    
  }

}
