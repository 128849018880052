import { Component, Input } from '@angular/core';
import { removeUserBtnOptions } from '@pages/admin-users/constants/button-options.const';
import { IUser } from '@models/data.models';
import { ModalService } from '@shared/services/app-modal-service.service';

@Component({
  selector: 'app-remove-user',
  templateUrl: './remove-user.component.html',
  styleUrls: ['./remove-user.component.scss'],
})
export class RemoveUserComponent {
  @Input() user: IUser;
  public removeUserBtnOptions = removeUserBtnOptions;
  public title = 'Remove';

  constructor(public modalService: ModalService) {
  }

  close() {
    this.modalService.cancel();
  }

  removeUser() {
    this.modalService.close(true);
  }
}
