import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { ButtonKind, ErrorState, LabelPosition, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';

export const searchOptions: SearchOptions = {
  placeholder: 'Search by ID',
  size: Size.md,
};
export const buttonOptions: ButtonOptions = {
  theme: Themes.white,
  kind: ButtonKind.primaryLoud,
  size: Size.md,
  width: WidthState.fixed,
  icon: 'dds-icon_filter',
  role: 'button',
}
export const selectOptions: SelectOptions = {
  labelPosition: LabelPosition.external,
  placeholder: 'Today',
  size: Size.md,
  errorState: ErrorState.default,
  stickerWidth: 160,
  stickerPosition: "bottom-right",
};
