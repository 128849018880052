import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ButtonKind, Size, Themes, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { ToastOptions } from '@usitsdasdesign/dds-ng/toast';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

export const removeOptions: ButtonOptions = { theme: Themes.green, kind: ButtonKind.secondary, size: Size.md };
export const createOptions: ButtonOptions = { theme: Themes.green, kind: ButtonKind.primaryLoud, size: Size.md };
export const searchOptions: SearchOptions = { placeholder: 'Search by name' };

export const closeBtnOptions: ButtonOptions = {
  theme: Themes.white,
  kind: ButtonKind.primaryLoud,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_close',
  role: 'button',
};

export const deleteBtnOptions: ButtonOptions = {
  theme: Themes.white,
  kind: ButtonKind.primaryLoud,
  size: Size.md,
  width: WidthState.fixed,
  isLoading: false,
  icon: 'dds-icon_sm dds-icon_delete',
  role: 'button',
};

export const addBtnOptions: ButtonOptions = {
  theme: Themes.green,
  kind: ButtonKind.primaryLoud,
  size: Size.md,
  width: WidthState.fixed,
  icon: 'dds-icon_plus',
  role: 'button',
};

export const removeUserBtnOptions: ButtonOptions = {
  theme: Themes.danger,
  kind: ButtonKind.primaryLoud,
  size: Size.md,
  width: WidthState.fixed,
  role: 'button',
}

export const noBtnOptions = {
  theme: Themes.dark,
  kind: ButtonKind.primary,
  size: Size.md,
  width: WidthState.fixed,
  role: 'button',
}

export const toastOptions: ToastOptions = {
  title: 'Changes were saved',
  message: '',
  // action: { title: 'Undo', actionFunc: ($event: Event) => console.log('Some Undo logic.') },
  lifeTime: 3000,
  position: 'bottom-center',
  closeBtnIcon: 'dds-icon_close',
  isCloseIcon: true,
  customClass: 'toast-message',
  isError: false,
  isLoading: false,
  limit: 5,
  theme: Themes.green,
};

export const tooltipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipPosition: 'left',
  tooltipIndent: 15,
  tooltipType: 'regular',
  tooltipSize: 'md',
};
