<dds-select
  label="Logistics Area"
  *ngIf="!logisticarea.length"
  [size]="size"
  [placeholder]="placeholder"
  [stickerMaxHeight]="'250'"
  [isDisabled]="true"
  [value]="selectedlogisticarea"
>
</dds-select>


<dds-select
  *ngIf="!!logisticarea.length"
  label="Logistics Area"
  [size]="size"
  [placeholder]="placeholder"
  [stickerMaxHeight]="'250'"
  [isDisabled]="disabled"
  [value]="selectedlogisticarea"
  (valueChanged)="onValueChange($event)"
>
  <dds-select-item #logisticAreaDropdown
    *ngFor="let item of logisticarea"
    [heading]="item.logisticsAreaName"
    [value]="item.logisticsAreaID.toString()"
  ></dds-select-item>
</dds-select>
