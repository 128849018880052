<app-common-modal-container [title]="title" (onClose)="close()" [borders]="false">
  <ng-container body>
    <div class="dds-body">Are you sure you want to remove {{ user?.firstName }} {{ user?.lastName }}?</div>
  </ng-container>

  <ng-container footer>
    <div class="dds-modal__footer-content dds-modal__footer-content_right">
      <dds-button
        class="close-button"
        kind="secondary"
        width="fixed"
        type="button"
        (clicked)="close()"
        (keyboardClicked)="close()"
      >
        No
      </dds-button>
      <dds-button
        type="button"
        width="fixed"
        [theme]="removeUserBtnOptions.theme"
        (keyboardClicked)="removeUser()"
        (clicked)="removeUser()"
      >
        Yes, remove
      </dds-button>
    </div>
  </ng-container>
</app-common-modal-container>
